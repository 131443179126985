import Vue from "vue";
import Vuex from "vuex";
import { createQueryString } from "../js/helpers";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        layout: "list",
        params: new URLSearchParams(window.location.search) ?? null,
        list: {
            properties: [],
            page: 1,
            pagination: {},
            transitionNeeded: false
        },
        map: {
            query: "",
            propertiesGeoJSON: {
                type: "FeatureCollection",
                features: [],
            },
        },
    },
    actions: {
        // Global layout
        setLayout({ commit }, layout) {
            commit("SET_LAYOUT", layout);
        },
        // List
        loadListProperties({ commit }) {
            const params = {
                paginate: 1,
                page: this.getters.listPage,
            };

            const apiUrl = `/api/properties.json${createQueryString(params)}`;

            axios({ url: apiUrl, method: "GET" }).then((result) => {
                if (result.status === 200 && result?.data?.data) {
                    // prettier-ignore
                    commit("SET_LIST_PROPERTIES", [...this.getters.listProperties,...result.data.data,]);
                    commit("SET_LIST_PAGINATION", result.data.meta.pagination);
                } else {
                    console.error("API error.", result);
                }
            });
        },
        loadMoreListProperties({ commit, dispatch }) {
            commit("SET_TRANSITION", true);
            commit("INCREMENT_LIST_PAGE"); // Bump our list page by 1
            dispatch("loadListProperties"); // Load next page of properties
        },
        // Map
        loadMapProperties({ dispatch }) {
            const params = {
                type: "property",
                location: this.state.map.query,
            };

            
            const apiUrl = `/api/properties.json${createQueryString(params)}`;

            axios({ url: apiUrl, method: "GET" }).then((result) => {
                if (result.status === 200 && result?.data?.data) {
                    dispatch("createGeoJSON", result.data.data);
                } else {
                    console.error("API error.", result);
                }
            });
        },
        createGeoJSON({ commit }, properties) {
            // prettier-ignore
            commit("SET_MAP_GEOJSON_FEATURES",
                properties.map((property) => ({
                    type: "Feature",
                    properties: {
                        id: property.id,
                        title: property.title,
                        url: property.url,
                        image: property.image,
                        street: property.address.street,
                        number: property.address.number,
                        postcode: property.address.postcode,
                        city: property.address.city,
                        status: property.status
                    },
                    geometry: {
                        type: "Point",
                        coordinates: [
                            property.coordinates.lng,
                            property.coordinates.lat,
                        ],
                    },
                }))
            );
        },
        searchLocation({ commit }, event) {
            commit("SET_SEARCH_QUERY", event.target.value);
        },
    },
    mutations: {
        SET_LAYOUT(state, layout) {
            state.layout = layout;
        },
        SET_LIST_PROPERTIES(state, properties) {
            state.list.properties = properties;
        },
        SET_LIST_PAGINATION(state, pagination) {
            state.list.pagination = pagination;
        },
        INCREMENT_LIST_PAGE(state) {
            state.list.page = state.list.page + 1;
        },
        SET_MAP_GEOJSON_FEATURES(state, features) {
            state.map.propertiesGeoJSON.features = features;
        },
        SET_SEARCH_QUERY(state, query) {
            state.map.query = query;
        },
        SET_TRANSITION(state, transitionNeeded) {
            state.list.transitionNeeded = transitionNeeded;
        }
    },
    getters: {
        layout(state) {
            return state.layout;
        },
        listPagination(state) {
            return state.list.pagination;
        },
        listProperties(state) {
            return state.list.properties;
        },
        listPage(state) {
            return state.list.page;
        },
        canLoadMore(state) {
            // prettier-ignore
            return state.list.properties.length > 0 && state.list.page !== state.list.pagination.total_pages;
        },
        mapPropertiesGeoJSON(state) {
            return state.map.propertiesGeoJSON;
        },
        searchQuery(state) {
            return state.map.query;
        },
        listTransitionNeeded(state) {
            return state.list.transitionNeeded;
        },
        params(state) {
            return state.params;
        }
    },
});
