import lazySizes from "lazysizes";
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import Swiper, { Pagination, Autoplay } from "swiper";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import store from "./store";
// import { RayPlugin } from "vue-ray/vue2";
import VueSmoothScroll from "vue2-smooth-scroll";
import { VueMasonryPlugin } from "./masonry.plugin"; // Custom version with video support

lazySizes.cfg.init = false; // Auto init goes wrong sometimes

Swiper.use([Pagination, Autoplay]);

const main = async () => {
    // prettier-ignore
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ "vue");
    // prettier-ignore
    const { default: VueCookies } = await import(/* webpackChunkName: "vue-cookies" */ "vue-cookies");
    // prettier-ignore
    const { default: axios } = await import( /* webpackChunkName: "axios" */ "axios");

    Vue.use(VueCookies);
    Vue.use(VueMasonryPlugin);
    Vue.use(VueSmoothScroll, {
        offset: 0,
        updateHistory: false,
    });

    // Vue.use(RayPlugin, {
    //     interceptErrors: true,
    //     host: "127.0.0.1",
    //     port: 23517,
    // });

    // prettier-ignore
    window.mapBoxToken = "pk.eyJ1IjoidGhlY3JhZnQiLCJhIjoiY2tvd2xvaDhvMDdhazJ1b2FveHRiMGdxaSJ9.YPq588vgGcA7rxjU6MLTiw";
    window.axios = axios;

    const vm = new Vue({
        el: "#site",
        delimiters: ["${", "}"],
        store,
        components: {
            // prettier-ignore
            "accordion": () => import(/* webpackChunkName: "accordion" */ "../vue/Accordion.vue"),
            // prettier-ignore
            "property-overview": () => import(/* webpackChunkName: "property-overview" */ "../vue/property/PropertyOverview.vue"),
            // prettier-ignore
            "inspiration-list": () => import(/* webpackChunkName: "inspiration-list" */ "../vue/inspiration/InspirationList.vue"),
            // prettier-ignore
            "property-images": () => import(/* webpackChunkName: "property-images" */ "../vue/PropertyImages.vue")
        },

        data: {
            // Navigation
            mobileNavIsOpen: false,
            hideNav: false,
            tightNav: false,
            fixateNav: false,
            lastScrollPos: 0,

            // Notifications
            showNotificationBar: true,
            // Form modals
            modals: [],
            // CookieFirst
            //advertisingConsent: false, // used to show/hide AddThis share buttons
        },

        created() {
            window.addEventListener("scroll", this.onWindowScroll);
        },

        mounted() {
            // Init lazysizes
            lazySizes.init();
            // Init formie
            if (window.Formie) {
                window.Formie.initForms();
            }
            // Cookie First consents
            //this.getCookieConsents();
            this.initHero();
            this.initVideo();
        },

        beforeDestroy() {
            window.removeEventListener("scroll", this.onWindowScroll);
        },

        methods: {
            onWindowScroll() {
                const isScrollingDown =
                    window.pageYOffset >= this.lastScrollPos;
                const siteContent = this.$refs.siteContent;
                const siteNavigationHeight = this.$refs.siteNavigation
                    ? this.$refs.siteNavigation.clientHeight
                    : 0;

                // Make site navigation fixed when scrolling down
                this.fixateNav = window.pageYOffset > 0;

                // Adjust site header position when site navigation snaps to fixed
                if (siteContent) {
                    siteContent.style.paddingTop = this.fixateNav
                        ? siteNavigationHeight + "px"
                        : 0;
                }

                // Create a hook to tweak site navigation (eg. make it smaller) when scrolling down
                this.tightNav =
                    isScrollingDown &&
                    window.pageYOffset > siteNavigationHeight;

                // Hide the site navigation on mobile when scrolling down and show it when scrolling up
                if (!this.mobileNavIsOpen) {
                    this.hideNav =
                        window.pageYOffset > this.lastScrollPos &&
                        window.pageYOffset > siteNavigationHeight;
                }

                this.lastScrollPos = window.pageYOffset;
            },

            /*
             async getCookieConsents() {
             while (!window.hasOwnProperty("CookieFirst")) // define the condition as you like
             await new Promise(resolve => setTimeout(resolve, 1000));
             
             // Set consents
             this.advertisingConsent = window.CookieFirst?.consent?.advertising || false;
             },
             */

            closeNotificationBar(slug) {
                VueCookies.set(`notification-${slug}`, "hidden", "7d");
                this.showNotificationBar = false;
            },

            toggleMobileNav() {
                this.mobileNavIsOpen = !this.mobileNavIsOpen;
                this.mobileNavIsOpen
                    ? disableBodyScroll(this.$refs.navMobile)
                    : enableBodyScroll(this.$refs.navMobile);
            },

            initHero() {
                const hero = document.querySelector(".hero");

                if (hero) {
                    const visual = hero.querySelector(
                        "#hero-swiper.swiper-container"
                    );
                    const pagination = hero.querySelector(".swiper-pagination");

                    new Swiper(visual, {
                        loop: false,
                        loopedSlides: 2,
                        speed: 850,
                        autoplay: {
                            delay: 5000
                        },
                        pagination: {
                            el: pagination,
                            clickable: true,
                        },
                    });
                }
            },

            initVideo() {
                const blockEl = document.querySelectorAll(".js-video");

                for (let block of blockEl) {
                    
                    const videoEl = block.querySelector("video");
                    const playBtnEl = block.querySelector('.btn-play');
                    // Remove controls by default
                    videoEl.removeAttribute("controls");
        
                    // If our video is not playing by default
                    // we add a click event that triggers once to play the video.
                    if (videoEl.paused) {
                        videoEl.addEventListener("click", (e) => {
                            e.preventDefault();
                            videoEl.play();
                        },
                        { once: true });
                    }
        
                    // Set video is playing state
                    videoEl.addEventListener("play", () => {
                        // Keeps track if the video has been played before or not (e.g. to hide the "play" icon after being initially played)
                        block.classList.add("has-played");
                        // Class added to the parent block so we know a video is currently playing
                        block.classList.add("playing");
                        // Add our default video controls
                        videoEl.setAttribute("controls", true);
                    });
        
                    // Remove video is playing state
                    videoEl.addEventListener("pause", () => {
                        // Display that the video is not playing anymore
                        block.classList.remove("playing");
                    });
                }
            },

            openModal(id) {
                if(this.modals.includes(id)) {
                    return;
                }

                this.modals.push(id);
            },
            closeModal(id) {
                const index = this.modals.indexOf(id);
                this.modals.splice(index, 1);
            },
        },

        computed: {
            
        },
    });

    return vm;
};

// Execute async function
main().then((vm) => {});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
