const createQueryString = function (params) {
    // Join params key-values into queryString
    let queryString = "";
    if (Object.keys(params).length !== 0) {
        for (let [key, value] of Object.entries(params)) {
            if (value != "") {
                queryString = `${queryString}&${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
            }
        }
        // Replace leading '&' by '?' -> replace by default only replaces first occurrence
        queryString = queryString.replace("&", "?");
    }

    return queryString;
};

export { createQueryString };
